// @flow
import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "@fas/react-scripts";
import App from "./components/App/App";

function prepare(): Promise<void> {
  if (process.env.NODE_ENV !== "production" && process.env.REACT_APP_DEV_SW) {
    // eslint-disable-next-line global-require
    const { mockApi } = require("./mocks/browser");
    return mockApi.makeServer();
  }
  return Promise.resolve();
}

prepare().then(() => {
  const rootElement = document.getElementById("root");

  if (rootElement) {
    const root = createRoot(rootElement);
    root.render(
      <App />
    );
  }
});
