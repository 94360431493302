// @flow

import useFormField from "@fas/ui-framework/lib/services/form/useFormField";
import { useEffect, useRef, useState } from "react";
import { SAVE_FORM_DATA_SAGA, GET_FORM_DATA_SAGA } from "@fas/ui-framework/lib/redux/constants";
import { useLoading } from "../../hooks/useLoading";
import {
  ANTIFRAUD_VARIABLE_FORM,
} from "../../helpers/constants";

// eslint-disable-next-line import/prefer-default-export
export function useResetFieldByFieldChange(watchFieldName: string, targetFieldName: string, resetValue: mixed = "") {
  const loading = useLoading(GET_FORM_DATA_SAGA, SAVE_FORM_DATA_SAGA);
  const { onChange } = useFormField(ANTIFRAUD_VARIABLE_FORM, targetFieldName);
  const { value } = useFormField(ANTIFRAUD_VARIABLE_FORM, watchFieldName);

  const isMount = useRef(true);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const prevValue = useRef(value);

  useEffect(() => {
    if (isMount.current) {
      isMount.current = false;
      return;
    }

    if (!loading && !isDataLoaded) {
      setIsDataLoaded(true);
      prevValue.current = value;
      return;
    }

    if (isDataLoaded && prevValue.current !== value) {
      onChange(resetValue);
    }

    prevValue.current = value;
  }, [value, loading, onChange, resetValue, isDataLoaded]);
}
