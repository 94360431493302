// @flow
import React, {
  useLayoutEffect,
  type StatelessFunctionalComponent, useEffect,
} from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Container,
  TextField,
  FormControlLabel,
  Switch,
  MenuItem,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  Loader,
  SetupCard,
} from "@fas/ui-core";
import FormHeader from "../FormHeader";
import type { Props } from "../../containers/AntifraudDictionaryForm";
import { DYNAMIC_DICT, VALUE_DICT } from "../../helpers/constants";
import type { SetAntifraudDictionaryFormField } from "../../actions/antifraudDictionaryForm";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100% - 64px)",
    backgroundColor: theme.palette.background.mainBg,
  },
  values: {
    minHeight: theme.spacing(20),
  },
}));

const AntifraudDictionaryForm: StatelessFunctionalComponent<Props> = ({
  getAntifraudDictionaryLoading,
  saveAntifraudDictionaryLoading,
  getAntifraudDictionaryActionsLoading,
  formData,
  onSetAntifraudDictionaryFormField,
  onGetAntifraudDictionarySaga,
  onSaveAntifraudDictionarySaga,
  onGetAntifraudDictionaryActions,
  errors,
  actionsList,
}: Props) => {
  const classes = useStyles();
  const { id } = useParams();

  useEffect(() => {
    onGetAntifraudDictionaryActions();
  }, []);

  useLayoutEffect(() => {
    if (id) {
      onGetAntifraudDictionarySaga(id);
    }
  }, [onGetAntifraudDictionarySaga, id]);

  return (
    <Box className={classes.root}>
      <FormHeader
        title={id ? `Edit Dictionary ${id}` : "Create Dictionary"}
        onSave={onSaveAntifraudDictionarySaga}
        renderComponent={(
          <Box
            component={FormControlLabel}
            pr={3}
            control={(
              <Switch
                disabled={getAntifraudDictionaryLoading}
                data-testid="isActive"
                name="isActive"
                checked={Boolean(formData.isActive)}
                onChange={({
                  target: {
                    checked,
                  },
                }: SyntheticInputEvent<HTMLInputElement>): SetAntifraudDictionaryFormField => (
                  onSetAntifraudDictionaryFormField({ isActive: checked })
                )}
                color="secondary"
              />
            )}
            label="Is active"
          />
        )}
        loading={saveAntifraudDictionaryLoading}
        isActionVisible={!getAntifraudDictionaryLoading}
      />
      <Container maxWidth="md">
        <Loader loading={getAntifraudDictionaryLoading}>
          <SetupCard title="General options">
            <Box width={1}>
              <TextField
                data-testid="name"
                fullWidth
                label="Name"
                variant="outlined"
                type="string"
                margin="dense"
                size="small"
                value={formData.name}
                onChange={({
                  target: {
                    value,
                  },
                }: SyntheticInputEvent<HTMLInputElement>): SetAntifraudDictionaryFormField => (
                  onSetAntifraudDictionaryFormField({ name: value })
                )}
                error={Boolean(errors.name)}
                helperText={errors.name}
              />
              <TextField
                data-testid="description"
                fullWidth
                label="Description"
                variant="outlined"
                type="string"
                margin="dense"
                size="small"
                value={formData.description}
                onChange={({
                  target: {
                    value,
                  },
                }: SyntheticInputEvent<HTMLInputElement>): SetAntifraudDictionaryFormField => (
                  onSetAntifraudDictionaryFormField({ description: value })
                )}
                error={Boolean(errors.description)}
                helperText={errors.description}
              />
              <TextField
                select
                id="dictTypeName"
                data-testid="dictTypeName"
                variant="outlined"
                fullWidth
                margin="dense"
                size="small"
                label="Type"
                value={formData.dictTypeName}
                onChange={(e) => onSetAntifraudDictionaryFormField({ dictTypeName: e.target.value })}
                disabled={!!id}
              >
                <MenuItem value={VALUE_DICT}>{VALUE_DICT}</MenuItem>
                <MenuItem value={DYNAMIC_DICT}>{DYNAMIC_DICT}</MenuItem>
              </TextField>

              {formData.dictTypeName === VALUE_DICT && (
                <TextField
                  data-testid="value"
                  fullWidth
                  label="Elements"
                  variant="outlined"
                  multiline
                  inputProps={{
                    className: classes.values,
                  }}
                  margin="dense"
                  size="small"
                  value={formData.value}
                  onChange={({
                    target: {
                      value,
                    },
                  }: SyntheticInputEvent<HTMLInputElement>): SetAntifraudDictionaryFormField => (
                    onSetAntifraudDictionaryFormField({ value })
                  )}
                  error={Boolean(errors.value)}
                  helperText={errors.value}
                />
              )}

              {formData.dictTypeName === DYNAMIC_DICT && (
                <>
                  <TextField
                    select
                    id="scenario"
                    data-testid="scenario"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    size="small"
                    label="Scenario"
                    value={formData.scenario || ""}
                    disabled={getAntifraudDictionaryActionsLoading}
                    onChange={(e) => onSetAntifraudDictionaryFormField({ scenario: e.target.value })}
                    error={Boolean(errors.scenario)}
                    helperText={errors.scenario}
                  >
                    {actionsList.map(({ label, value }) => (
                      <MenuItem key={value} value={value}>{label}</MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    select
                    id="ttl"
                    data-testid="ttl"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    size="small"
                    label="TTL"
                    value={formData.ttl}
                    onChange={(e) => onSetAntifraudDictionaryFormField({ ttl: e.target.value })}
                  >
                    <MenuItem value={60}>60</MenuItem>
                    <MenuItem value={120}>120</MenuItem>
                    <MenuItem value={240}>240</MenuItem>
                  </TextField>
                </>
              )}
            </Box>
          </SetupCard>
        </Loader>
      </Container>
    </Box>
  );
};

export default AntifraudDictionaryForm;
